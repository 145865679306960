import {gsap} from 'gsap';
import {expandedVars} from '../vars';

export function ironSky() {
  const section = document.querySelector('.iron-sky');
  if (!section) return;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: 'top center'
    }
  })

  tl.from('.iron-sky__content', {
    autoAlpha: 0,
  })
  tl.from('.iron-sky__image img', {
    autoAlpha: 0,
    borderWidth: 0,
    scale: expandedVars.scaleUp.small,
  },'<')
}