import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {setTranslateX} from "../utils/setTranslateX";
import {textFromCenterToLeft} from "../utils/textFromCenterToLeft";
import { vars } from "../vars";

export function centreOfTheEarth() {
  const section = document.querySelector('.centre-of-the-earth');

  if (!section) {
    return false;
  }

  const title = section.querySelector('.centre-of-the-earth__title'),
    inner = section.querySelector('.centre-of-the-earth__inner'),
    content = section.querySelector('.centre-of-the-earth__content'),
    image = section.querySelector('.centre-of-the-earth__image'),
    splitTitleArray = new SplitText(title, {type: 'lines'}),
    splitContentArray = new SplitText(content, {type: 'lines'}),
    tlDuration = vars.duration,
    tl = gsap.timeline({
      scrollTrigger: {
        trigger: title,
        once: true
      },
      defaults: {
        duration: tlDuration,
        ease: "power2.out"
      }
    });

  // Get container width without paddings (5% * 2) and inner width
  const containerWidth = inner.parentElement.offsetWidth * 0.9,
    innerWidth = inner.offsetWidth;

  // Set .centre-of-the-earth__inner to center
  gsap.set(inner, { x: (containerWidth - innerWidth) / 2 })

  // Set translate to center lines
  splitTitleArray.lines.forEach(line => setTranslateX(line, inner));
  splitContentArray.lines.forEach(line => setTranslateX(line, inner));

  // Animate title lines
  splitTitleArray.lines.forEach((line, i) => {
    const direction = (i % 2) ? 15 : -15,
      delay = (i === 0) ? `-=${tlDuration}` : '<';

    tl.from(line, { xPercent: direction, autoAlpha: 0 }, delay);
  });

  // Animate content block animation
  tl.from(content, { autoAlpha: 0, yPercent: 15 }, `<`);

  // Animate inner block to left
  tl.to(inner, { x: 0 })

  // Animate image
  tl.from(image, { xPercent: 100 }, '<')

  // Animate title blocks to left
  splitTitleArray.lines.forEach(title => {
    textFromCenterToLeft(title, tl, inner)
  })

  // Animate content blocks to left
  splitContentArray.lines.forEach(paragraph => {
    textFromCenterToLeft(paragraph, tl, inner)
  })
}