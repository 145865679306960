import {gsap} from "gsap";

export function futureArticleAnim() {
  const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.futurism-article',
        start: 'top 70%',
      },
      duration: 1,
    }),
    title = document.querySelector('.futurism-article__title'),
    title2 = document.querySelector('.futurism-article__title--second'),
    imageTopLeft = document.querySelectorAll('.js-item-image-top-left'),
    imageTopRight = document.querySelectorAll('.js-item-image-top-right'),
    imageCenterLeft = document.querySelectorAll('.js-item-image-center-left'),
    imageCenterRight = document.querySelectorAll('.js-item-image-center-right'),
    imageBottomLeft = document.querySelectorAll('.js-item-image-bottom-left'),
    imageBottomRight = document.querySelectorAll('.js-item-image-bottom-right');
  tl.from(title, {
    autoAlpha: 0,
    y: 300
  });
  tl.from(title2, {
    autoAlpha: 0,
    y: 300
  });
  tl.to(imageTopLeft, {
    transform: "translate(0, 0) rotate(-25deg)",
  }, '<');
  tl.to(imageTopRight, {
    transform: "translate(0, 0) rotate(41deg)",
  }, '<');
  tl.to(imageCenterRight, {
    transform: "translate(0, 0) rotate(41deg)",
  }, '<');
  tl.to(imageCenterLeft, {
    transform: "translate(0, 0) rotate(-60deg) scaleX(-1)",
  }, '<');
  tl.to(imageBottomLeft, {
    transform: "translate(0, 0) rotate(-60deg)",
  }, '<');
  tl.to(imageBottomRight, {
    transform: "translate(0, 0) rotate(-60deg)",
  }, '<');
}