import {gsap} from 'gsap';
import {expandedVars} from '../vars';

export function usaFlagAnim() {
  const section = document.querySelector('.usa-flag');

  if (!section) return;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: 'top center',
    }
  })

  tl.from('.usa-flag__img', {
    xPercent: 100,
    autoAlpha: 0,
  })
  tl.from('.usa-flag__content > *', {
    xPercent: -100,
    autoAlpha: 0,
    stagger: expandedVars.stagger.slow,
  }, '<')
}