import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {SplitText} from "gsap/SplitText";
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText, ScrollToPlugin, MotionPathPlugin);

import dreamTechnologyAnim from './section-animations/dreamTechnology';
import theLastFrontierAnim from './section-animations/theLastFrontier';
import {headerAnim} from "./section-animations/header";
import {heroAnim} from "./section-animations/heroAnim";
import {planeAnim} from "./section-animations/plane";
import {earthAnim} from './section-animations/earthAnim';
import {horizontalScroll} from './section-animations/horizontalScroll';
import {karlMarxAnim} from "./section-animations/karlMarx";
import {futureArticleAnim} from "./section-animations/futureArticle";
import {frArticleAnim} from "./section-animations/frArticle";
import {ovalThumbAnim} from "./section-animations/ovalThumb";
import {redRevolutionAnim} from "./section-animations/redRevolution";
import {flowersAnim} from "./section-animations/floversAnim";
import {techDevAnim} from './section-animations/techDev';
import {ovalImageAnim} from "./section-animations/ovalImage";
import {loveNRobotsAnim} from './section-animations/loveNRobots';
import {darkLightSectionAnimation} from './section-animations/darkLight';
import {imageSliderAnim} from "./section-animations/imageSlider";
import {hammerAndSickleAnim} from "./section-animations/hammerAndSickle";
import {boldestDreamsAnim} from "./section-animations/boldestDreams";
import {uncleSamAnim} from "./section-animations/uncleSam";
import {bigWorldAnim} from './section-animations/bigWorld';
import {sideBySide} from './section-animations/sideBySide';
import {distantStarsAnim} from "./section-animations/distantStars";
import {centreOfTheEarth} from "./section-animations/centreOfTheEarth";
import {medicineAnim} from "./section-animations/medicineAnim";
import {gigantomaniaAnim} from "./section-animations/gigantomania";
import {usaFlagAnim} from './section-animations/usaFlag';
import {todayDreamsAnim} from "./section-animations/todayDreams";
import {ironSky} from "./section-animations/ironSky";
import {spaceAdventuresAnim} from "./section-animations/spaceAdventuresAnim";
import { is } from "./utils/is";
import {endGreatFutureAnim} from './end-great-future';
import {footerAnim} from './footer';

const app = {
  fontsLoaded: false,
  domContentLoaded: false,
  scroller: false,

  hide: function() {
    gsap.set('body', {autoAlpha: 0});
  },
  show: function() {
    gsap.to('body', {autoAlpha: 1});
  }
}

app.fontsReady = function() {
  const self = this;

  if (document.fonts != undefined && app.fontsLoaded != true) {
    document.fonts.ready.then(function () {
      self.fontsLoaded = true;
      self.initAnimation();
    });
  } else {
    self.fontsLoaded = true;
  }
  // if something wrong
  setTimeout(() => {
    if (self.fontsLoaded != true) {
      self.fontsLoaded = true;
      self.initAnimation();
    }
  }, 1000)
}

app.domReady  = function() {
  const self = this;
  document.addEventListener('DOMContentLoaded', () => {
    self.domContentLoaded = true;
    
    self.initAnimation();
  });  
}

app.isReady = function() {
  // console.log(this.fontsLoaded, this.domContentLoaded)

  if (!this.fontsLoaded || !this.domContentLoaded) {
    return false;
  }

  return true
}

app.init = function() {
  this.hide();
  this.fontsReady();
  this.domReady();

  addBodyNavigatorClass();

  this.scroller = smoothScroll();
  this.heroAnim = heroAnim;
}

app.initAnimation = function() {
  
  if (!this.isReady()) return false;

  this.show();

  headerAnim();
  this.heroAnim();
  planeAnim();
  earthAnim();
  horizontalScroll();
  karlMarxAnim();
  futureArticleAnim();
  ovalThumbAnim();
  frArticleAnim();
  flowersAnim();
  techDevAnim();
  medicineAnim();
  ovalImageAnim();
  redRevolutionAnim();
  loveNRobotsAnim();
  darkLightSectionAnimation();
  imageSliderAnim();
  hammerAndSickleAnim();
  uncleSamAnim();
  bigWorldAnim();
  spaceAdventuresAnim();
  boldestDreamsAnim();
  sideBySide();
  //-
  centreOfTheEarth();
  theLastFrontierAnim();
  dreamTechnologyAnim();
  //-
  ironSky();
  gigantomaniaAnim();
  distantStarsAnim();
  usaFlagAnim();
  todayDreamsAnim();
  endGreatFutureAnim();
  footerAnim();
}

function detectMac() {
  const iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
    'Mac',
    'Mac68K',
    'MacPPC',
    'MacIntel'
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()){ return true; }
    }
  }

  return false;
}
function addBodyNavigatorClass() {
  let body = document.body;
  if (detectMac()) {
    body.classList.add('ios');
  }
}
function smoothScroll() {
  window.scrollTo(0, 0);

  if (is.mobile()) return false;
  return ScrollSmoother.create({
    wrapper: '#smooth-wrapper',
    content: '#smooth-content',
    normalizeScroll: true,
    smooth: 1.2
  })
}

app.init();