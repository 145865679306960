/**
 * This function add animation to timeline from center to left align text
 * @param text - object. Text, which needs to be animate from text-align: center to left
 * @param timeline - timeline of GSAP
 * @param section - object Parent section of text
 */

export function textFromCenterToLeft(text, timeline, section) {
  timeline.to(text, {
    x: 0,
  }, '<')
}