const clearStyles = function(elements) {
    if (typeof elements === 'array' || !(elements instanceof HTMLElement)) {
        elements.forEach((item)=> {
          item.removeAttribute('style');
        });
    } else {
        elements.removeAttribute('style');
    }
}

const clearTweenStyles = function(tween) {
    clearStyles(tween.targets());
}

export {
    clearStyles,
    clearTweenStyles
}
