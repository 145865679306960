import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import { vars } from "../vars";

export function karlMarxAnim() {
  const section = document.querySelector('.karl-marx');
  if (!section) return false;

  const title = section.querySelector('.karl-marx__title');
  const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: vars.scrollTrigger.start,
      },
      onStart: () => {
        alignTitleAnimation();
      }
    }),
    titleSplitText = new SplitText(title, {type: "words,chars, lines"}),
    karlImg = section.querySelector('.karl-marx__wrapper'),
    chars = titleSplitText.chars; //an array of all the divs that wrap each character
  
  const alignTitleAnimation = () => {
    if (!titleSplitText.isSplit) {
      titleSplitText.split();
    }
    titleSplitText.lines.forEach((line) => {
      gsap.set(line, {textAlign: 'center'});
      let x = line.children[0].getBoundingClientRect().x - line.getBoundingClientRect().x;
      gsap.set(line, {
        textAlign: 'left',
        x: x
      });
    });
  }
  
  tl.set(title, {
    x: () => {
      return gsap.getProperty(title, "width", '%') === '100%' ? 0 : gsap.getProperty(karlImg, "width") / 2
    },
  }, 0);
  

  tl.from(chars, {
    yPercent: 110,
    stagger: 0.01,
    duration: 1,
  });

  tl.to(titleSplitText.lines, {
    x:0, 
    duration: 0.5,
    // onComplete: () => titleSplitText.revert()
  });
  
  tl.to(title, {
    duration: 0.5,
    x: 0
  }, '<');
  
  tl.set(karlImg, {
    xPercent: 150,
  }, 0);
  tl.to(karlImg, {
    xPercent: 0,
    duration: 0.5,
  }, '-=0.5');
}