import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { vars } from "../vars";
import { scrollHeightPercent, scrollWidthPercent } from "../utils/scrollPercent";
import { clearStyles } from "../utils/clearStyles";

export function medicineAnim() {
  const section = document.querySelector('.fr-medicine');

  if (!section) return false;

  let tl;
  const
    title = section.querySelector('.futurism-title'),
    content = section.querySelectorAll('.js-fr-medicine__content p'),
    leftScroll = section.querySelector('.js-vertical-scroll--first'),
    rightScroll = section.querySelector('.js-vertical-scroll--second'),
    topYLeft = scrollHeightPercent(leftScroll) * -1,
    topYRight = scrollHeightPercent(rightScroll) * -1;

  const sectionWrapper = document.querySelector('.fr-medicine-wrap');
  let pinSpacer = false;

  function getBackground(el) {
    let backgroundEl = el.querySelector('.section__background') || el;
    let background = window.getComputedStyle(backgroundEl, true).background;
    let backgroundAfter = window.getComputedStyle(backgroundEl, ':after').background;
    let opacityAfter = window.getComputedStyle(backgroundEl, ':after').opacity;

    let after = false;

    if (el.querySelector('.section__background')) {
      after = {
        background: backgroundAfter,
        opacity: opacityAfter
      }
    }

    return {
      background: background,
      after: after
    };
  }
  
  function addBackgroundHelper(section, positionTop = true) {
    
    let el = positionTop ? section.previousElementSibling : section.nextElementSibling;

    let background = getBackground(el);

    const bgEl = document.createElement("div");
    bgEl.classList.add('section__background-helper');
    if (positionTop) {
      bgEl.classList.add('section__background-helper--top');
    } else {
      bgEl.classList.add('section__background-helper--bottom');
    }
    let style = 'background:' + background.background + ';';
    if (background.after) {
      style = style + '--after-background:' + background.after.background + ';';
      style = style + '--after-opacity:' + background.after.opacity + ';';
    }
    section.querySelector('.fr-medicine').appendChild(bgEl);

    bgEl.setAttribute('style', style );
  }

  const initTimeline = (params) => {
    if (tl) {
      tl.kill();
    }
    let scrollTrigger = Object.assign({
      trigger: section,
      start: 'top center',
      end: 'bottom top',
      scrub: true,
      toggleActions: 'play none none reverse',
    }, params);

    tl = gsap.timeline({
      scrollTrigger: scrollTrigger
    });
  }

  const updateGallery = () => {
    tl.getChildren().forEach((tween) => {
      const item = tween.targets()[0];
      const y = scrollHeightPercent(item);
      gsap.set(leftScroll, {
        data: {
          y: y * -1,
          x: scrollWidthPercent(item) * -1,
        },
      });
      tween.progress( tween.progress() + 0.01 )
    });
    tl.scrollTrigger.refresh();
  }

  ScrollTrigger.matchMedia({
    // mobile
    "(max-width: 767px)": function() {
      initTimeline({
        start: 'top center',
        end: 'bottom top',
        scrub: true,
      })
      gsap.set(leftScroll, {yPercent: 0});
      gsap.from(leftScroll, {
        yPercent: 20,
        autoAlpha: 0,
        duration: vars.duration,
        scrollTrigger: {
          trigger: leftScroll,
          start: vars.scrollTrigger.start
        }
      });
      tl.from(leftScroll, {
        ease: "power1",
        data: {
          x: scrollWidthPercent(leftScroll) * -1,
        },
        duration: vars.duration,
        onUpdate: function() {
          gsap.set(leftScroll, {
            xPercent: this.progress() * this.vars.data.x,
          });
        }
      }, '<');
      updateGallery();
    },

    // desktop
    "(min-width: 768px)": function() {
      initTimeline(
        {
          start: 'top top',
          invalidateOnRefresh: true,
          end: () => {
            let hasScroll = false;
            tl.getChildren().forEach((tween) => {
              const item = tween.targets()[0];
              const y = scrollHeightPercent(item);
              hasScroll = hasScroll !== true ? y > 0 : hasScroll;
              tween.vars.data.y = y * -1
            });

            return hasScroll ? false : 'top top'
          },
          scrub: 1,
          snap: 3,
          pin: true,
        }
      );

      pinSpacer = sectionWrapper.querySelector('.pin-spacer');

      if (pinSpacer) {
        addBackgroundHelper(sectionWrapper);
        addBackgroundHelper(sectionWrapper, false);
      }

      const progress = tl.progress();

      gsap.set(leftScroll, {
        xPercent: 0,
        autoAlpha: 1,
        yPercent: (1 - progress) * topYLeft,
      });

      tl.to(leftScroll, {
        data: {
          y: topYLeft,
        },
        duration: 2,
        ease: "power1",
        onUpdate: function() {
          const progress = this.progress();
          const y = this.vars.data.y;
          gsap.set(leftScroll, {
            yPercent: (1 - progress) * y,
          });
        }
      }, '<');
  
      tl.to(rightScroll, {
        data: {
          y: topYRight,
        },
        duration: 2,
        ease: "power1",
        onUpdate: function() {
          const y = this.vars.data.y;
          gsap.set(rightScroll, {
            yPercent: this.progress() * y,
          })
        }
      }, '<');
      updateGallery();
    }
  });

  gsap.from(title, {
    autoAlpha: 0,
    y: '1.5em',
    duration: vars.duration,
    scrollTrigger: {
      trigger: title,
      start: vars.scrollTrigger.start
    }
  });

  gsap.from(content, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    scrollTrigger: {
      trigger: content,
      start: vars.scrollTrigger.start,
    }
  });

  window.addEventListener('resizeEnd', (event) => {
    updateGallery();
    setTimeout(function() {
      tl.scrollTrigger.refresh()
    }, 150);
  });
}