import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import { vars } from "../vars";
import { clearTweenStyles } from "../utils/clearStyles";

function theLastFrontierAnimDefault(trigger, delay = 0) {
	return {
		autoAlpha: 0,
		yPercent: 10,
		duration: vars.duration,
		delay: delay,
		scrollTrigger: {
			trigger: trigger,
			// toggleActions: 'restart reverse restart reverse'
		}
	}
}

function theLastFrontierAnim() {
	const section = document.querySelector('.the-last-frontier');
	
	if (!section) {
		return false;
	}
	
	const title = document.querySelector('.the-last-frontier__title--large'),
		tl = gsap.timeline({
			scrollTrigger: {
				trigger: title,
				// toggleActions: 'restart reverse restart reverse'
			}
		}),
		mySplitText = new SplitText(title, {type: "words, chars"}),
		chars = mySplitText.chars; //an array of all the divs that wrap each character
	
	tl.from(chars, {
		yPercent: 100,
		duration: vars.duration,
		stagger: 0.02
	});
	
	const subtitles = section.querySelectorAll('.the-last-frontier__title'),
		content = section.querySelectorAll('.the-last-frontier__content'),
	mobileImage = section.querySelector('.the-last-frontier__mobile-image');
	
	subtitles.forEach(title => {
		gsap.from(title, theLastFrontierAnimDefault(title, 1))
	})
	
	gsap.from(content, theLastFrontierAnimDefault(content, 1))

	if (mobileImage) {
		gsap.from(mobileImage, theLastFrontierAnimDefault(mobileImage))
	}
	
	const images = document.querySelectorAll('.the-last-frontier-images__item');

	images.forEach((image, i) => {
		const xDirection = (i == 1) ? 100 : 0;
		gsap.from(image, {
			scrollTrigger: {
				trigger: image,
				// toggleActions: 'restart reverse restart reverse',
			},
			autoAlpha: 0,
			yPercent: 10,
			xPercent: xDirection,
			scale: 0,
			onComplete: function() {
				clearTweenStyles(this);
			}
		})
	})
}

export default theLastFrontierAnim;