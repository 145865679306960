import {gsap} from 'gsap';
import {expandedVars, vars} from '../vars';

export function loveNRobotsAnim() {
  const section = document.querySelector('.love-n-robots');

  if (!section) return

  const image = section.querySelector('.love-n-robots__image img');
  const content = section.querySelector('.love-n-robots__content');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: vars.scrollTrigger.start,
    }
  })

  tl.from(image, {
    scale: expandedVars.scaleUp.extraLarge,
    autoAlpha: 0,
    duration: vars.duration,
  })

  tl.from(content, {
    y: 100,
    autoAlpha: 0,
    duration: vars.duration,
  }, '<')
}