import {gsap} from 'gsap';
import { vars } from '../vars';

export function sideBySide() {
  const section = document.querySelector('.side-by-side');
  if (!section) return;

  const sectionMars = section.querySelector('.sbs-item--mars');
  const sectionNeptune = section.querySelector('.sbs-item--neptune');
  const sectionPluto = section.querySelector('.sbs-item--pluto');
  const sectionJupiter = section.querySelector('.sbs-item--jupiter');
  const easeRight = gsap.utils.toArray('.ease-right');
  const easeLeft = gsap.utils.toArray('.ease-left');
  const marsTl = gsap.timeline({
    scrollTrigger: {
      trigger: sectionMars,
      start: 'top center',
    }
  })
  marsTl.from(easeRight, {
    x: 300,
    autoAlpha: 0,
    stagger: 0.5,
    duration: 0.5,
  })
  marsTl.from(easeLeft, {
    x: -300,
    autoAlpha: 0,
    stagger: 0.5,
    duration: 0.5,
  }, '<')

  const neptuneTl = gsap.timeline({
    scrollTrigger: {
      trigger: sectionNeptune,
      start: 'top center',
    }
  })

  neptuneTl.from(sectionNeptune.querySelector('.sbs-item-frog__bg'), {
    autoAlpha: 0,
  })
  neptuneTl.from(sectionNeptune.querySelector('.sbs-item-frog__planet'), {
    autoAlpha: 0,
    scale: 0,
  }, '<')
  neptuneTl.from(sectionNeptune.querySelector('.sbs-item-frog__frog'), {
    autoAlpha: 0,
    scale: 0.5,
    transformOrigin: 'bottom center'
  }, '<')

  const neptuneContentTl = gsap.timeline({
    scrollTrigger: {
      trigger: sectionNeptune,
      start: 'top center',
    },
  })
  neptuneContentTl.from(sectionNeptune.querySelector('.sbs-item__title'), {
    y: 50,
    autoAlpha: 0,
  })
  neptuneContentTl.from(gsap.utils.toArray('.sbs-item--neptune .sbs-item__content p'),{
    y: 100,
    autoAlpha: 0
  })

  gsap.fromTo('.sbs-item-bat__bat img', {
    scale: 0.5,
  }, {
    yPercent: -300,
    scale: 2,
    autoAlpha: 0,
    scrollTrigger: {
      trigger: sectionPluto,
      start: 'top 70%',
      scrub: true,
    }
  })

  gsap.from(sectionJupiter, {
    opacity: 0.5,
    y: 100,
    duration: vars.duration,
    scrollTrigger: {
      trigger: sectionJupiter,
      start: vars.scrollTrigger.start
    }
  })

  const jupiterTl = gsap.timeline({
    scrollTrigger: {
      trigger: sectionJupiter,
      start: vars.scrollTrigger.start
    }
  })

  jupiterTl.from('.sbs-item--jupiter .sbs-item__content p', {
    autoAlpha: 0,
    y: 300,
  })

  jupiterTl.to('.sbs-item-jupiter__foreground', {
    xPercent: -56,
    yPercent: 33,
    rotate: -18,
    scale: 0.6
  })
}