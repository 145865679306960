import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import { vars } from "../vars";

export function boldestDreamsAnim() {
  const section = document.querySelector('.boldest-dreams-section');

  if(!section) { return false; }

  let title = section.querySelectorAll('.section__title'),
    linesSplitText = new SplitText(title, {
      type: 'lines'
    }),
    diamond = section.querySelectorAll('.diamond'),
    planets = section.querySelectorAll('.planet'),
    content = section.querySelectorAll('.section__content p');

  let lines = linesSplitText.lines;

  function notPlanet(element) {
    const isPlanet = element.querySelectorAll('.planet').length > 0;
    return isPlanet ? false : element;
  }

  lines = lines.filter(notPlanet);

  lines.forEach((line, index) => {
    if (index === 1) {
      line.classList.add('line-indent')
    }
    gsap.from(line, {
      autoAlpha: 0,
      x: (index % 2 ? '' : '-') + '14%',
      duration: vars.duration,
      scrollTrigger: {
        trigger: line,
        start: vars.scrollTrigger.start
      }
    });
  });

  gsap.from(content, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    scrollTrigger: {
      trigger: content,
      start: vars.scrollTrigger.start
    }
  });

  gsap.from(diamond, {
    autoAlpha: 0,
    x: '-2%',
    duration: vars.duration,
    scrollTrigger: {
      trigger: diamond,
      start: vars.scrollTrigger.start
    }
  });

  planets.forEach(function(planet) {
    gsap.from(planet, {
      autoAlpha: 0,
      scale: vars.scaleDown.middle,
      duration: vars.duration,
      scrollTrigger: {
        trigger: planet,
        start: vars.scrollTrigger.start
      },
      onComplete: function() {
        let targets = this.targets();
        targets.forEach(function(item) {
          item.setAttribute('style', '');
        })
      }
    });
  });
}