import {gsap} from "gsap";

function percentToPixel(_elem, _perc){
  return (_elem.parentElement.clientWidth/100)* parseFloat(_perc);
}

export function imageSliderAnim() {
  const images = document.querySelector('.image-slider-images');

  if(!images) { return false; }

  const title = document.querySelector('.image-slider__title'),
    subtitle = document.querySelector('.image-slider__subtitle'),
    titleAnimDuration = 1.5,
    tl = gsap.timeline({
      scrollTrigger: '.image-slider',
      defaults: {autoAlpha: 0, ease: "power2.out", duration: titleAnimDuration}
    });

  tl.from(title, {xPercent: -50})
    .from(subtitle, {xPercent: 50}, `-=${titleAnimDuration}`)
    
  let slider = gsap.to(images, {
    translateX: -percentToPixel(images, 100),
    data: {
      x: -percentToPixel(images, 100),
    },
    onUpdate: function() {
      gsap.set(images, {
        translateX: this.data.x * this.progress()
      });
    },
    scrollTrigger: {
      trigger: '.image-slider',
      scrub: 1
    }
  })

  window.addEventListener('resize', (event) => {
    slider.data.x = -percentToPixel(images, 100);
  
    setTimeout(function() {
      slider.scrollTrigger.refresh();
    }, 150)
  });
}