import {gsap} from 'gsap';
import {SplitText} from 'gsap/SplitText';
import {expandedVars, vars} from '../vars';

export function earthAnim() {
  const section = document.querySelector('.earth');
  if (!section) return false;

  const title = new SplitText(section.querySelector('.earth__title'), {type: 'chars, words'});
  const titleChars = title.chars;
  const subtitle = new SplitText('.earth__subtitle', {type: 'lines'});
  const earthImg = section.querySelector('.earth-img');
  const posters = section.querySelector('.earth-posters');
  const postersItems = posters.querySelectorAll('.earth-posters__item');

  const tl = gsap.timeline({
    onComplete: () => {
      if (earthImg.classList.contains('is-waiting')) {
        earthImg.classList.remove('is-waiting')
        earthImg.classList.add('is-active')
      }
    },
    scrollTrigger: {
      trigger: '.earth__wrapper',
      start: () => window.innerWidth < 768 ? 'top 70%' : 'top 20%',
      end: () => window.innerWidth < 768 ? 'bottom 50%' : 'bottom 64%',
      onLeave: () => {
        if (!tl.isActive()) {
          earthImg.classList.add('is-active');
        } else {
          earthImg.classList.add('is-waiting');
        }
      }
    },
  });

  tl.from(titleChars, {
    yPercent: 100,
    autoAlpha: 0,
    stagger: 0.02
  });
  tl.from(subtitle.lines, {
    yPercent: 100,
    stagger: expandedVars.stagger.slow,
    autoAlpha: 0,
  })
  tl.from(earthImg, {
    yPercent: 100,
    duration: expandedVars.duration.fast,
    autoAlpha: 0,
  }, '<')

  const postersTl = gsap.timeline({
    scrollTrigger: {
      trigger: posters,
      start: vars.scrollTrigger.start,
      scrub: 1,
      invalidateOnRefresh: true,
    }
  });
  gsap.set(posters, {
    autoAlpha: 0,
  })

  postersTl.to(posters, {
    autoAlpha: 1,
    xPercent: () => {
      let x = parseInt(gsap.getProperty(postersItems[0], 'width', '%'), 10);
      x = 100 - ( x * postersItems.length);
      return x;
    }
  }, '<')
  
  const bg = section.closest('.js-bg-change');
  window.postersTl = postersTl;
  let bgOpacity = postersTl.progress().toFixed(2);
  postersTl.to(bg, {
    backgroundColor: '#222222',
    onUpdate: () => {
      let bgOpacityNew = postersTl.progress().toFixed(3);
      if (bgOpacity != bgOpacityNew) {
        bgOpacity = bgOpacityNew;
        gsap.set(bg, {'--opacity': bgOpacity})
      }
    }
  }, '<');
}