/**
 * Custom scripts.
 */
import './common/animations'

var evtResize = new CustomEvent("resizeEnd");
var evtResizeOnce = new CustomEvent("resizeOnce");

window.addEventListener("resize", () => {
    clearTimeout(window.resizeEnd);
    window.resizeEnd = setTimeout(function() {
        window.dispatchEvent(evtResize);
    }, 100);
});

let windowWindth = window.innerWidth;
const resizeOnceFn = () => {
    if (windowWindth !== window.innerWidth) {
        window.dispatchEvent(evtResizeOnce);
        window.removeEventListener('resize', resizeOnceFn);
        windowWindth = window.innerWidth;
    }
}
window.addEventListener("resize", resizeOnceFn);

// dev
const sectionNameHelperStyles = `
.element-helper {
    position: absolute;
    padding: 5px 10px;
    left: 0;
    top: 10px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    background-color: #fff;
    color: #000;
    z-index: 10;

    .header & {
        display: none;
    }
}
section,
.wrapper > div {
    position: relative;
}
`;
const sectionNameHelper = function() {
    let wrapper = document.querySelector('.wrapper');
    let children = wrapper.children;
    for (let el of children) {
        let elementClass = el.getAttribute('class')
        elementClass = elementClass.replaceAll('-', ' ');
        let elementHelper = document.createElement('div')
        elementHelper.classList.add('element-helper');
        elementHelper.innerText = elementClass
        
        el.prepend(elementHelper)
    }

    const styleEl = document.createElement('style');
    styleEl.innerText = sectionNameHelperStyles;
    document.body.append(styleEl);
}

if (location.search.search('dev') >= 0) {
    sectionNameHelper();
}
