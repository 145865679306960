import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { scrollHeightPercent } from "../utils/scrollPercent";
import { vars } from "../vars";

export function spaceAdventuresAnim() {

    const section = document.querySelector('.space-adventures-section');
    
    if (!section) return false;
    
    const gallery = section.querySelector('.gallery');
    const track = [].slice.call(gallery.querySelectorAll('.gallery__track'));
    const title = section.querySelector('.section__title');
    const content = section.querySelectorAll('.section__content p');
    let tl;

    gsap.from(title, {
        autoAlpha: 0,
        y: '1.5em',
        duration: vars.duration,
        stagger: vars.stagger,
        scrollTrigger: {
            trigger: title,
            start: vars.scrollTrigger.start,
        }
    });
    
    gsap.from(content, {
        autoAlpha: 0,
        y: '3em',
        duration: vars.duration,
        stagger: vars.stagger,
        scrollTrigger: {
            trigger: content,
            start: vars.scrollTrigger.start,
        }
    });
    
    const initTimeline = (trigger) => {
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline({
            scrollTrigger: {
                trigger: trigger,
                start: 'top top',
                end: () => "+=25%",
                toggleActions: 'play none none reverse',
                scrub: 1,
                pin: false
            }
        });
    }
    
    const initGallery = () => {
        track.forEach((item, index) => {
            const y = scrollHeightPercent(item) * -1;
            const isEven = index % 2 ? true : false;
    
            gsap.set(item, {
                yPercent: isEven ? 0 : y,
            });
            
            gsap.from(item, {
                duration: vars.duration,
                autoAlpha: 0,
                ease: "power1",
                yPercent: isEven ? -100 : 100,
                scrollTrigger: {
                    trigger: gallery,
                    start: vars.scrollTrigger.start
                }
            });
            
            tl.to(item, {
                data: {
                    isEven: isEven,
                    y: y
                },
                duration: vars.duration,
                ease: "power1",
                onUpdate: function() {
                    updateTrack(item, this)
                },
            }, '<');
        });

        updateGallery();
    }


    const updateTrack = (item, tween) => {
        if (
            gsap.getProperty(item, "opacity") === 0 && tween.progress() > 0 ||
            gsap.getProperty(item, "opacity") === 0 && tl.scrollTrigger.isActive === false
            ) {
            gsap.to(item, {
                duration: vars.duration,
                autoAlpha: 1
            });
        }

        const y = tween.data.isEven ? (tween.progress() * tween.data.y) : ((1 - tween.progress()) * tween.data.y);

        gsap.to(item, {
            yPercent: y,
            duration: vars.duration,
        });
    }

    const updateGalleryHeight = () => {
        let galleryHeight = gsap.getProperty(gallery, 'scrollHeight');
        let innerHeight = 0;
        tl.getChildren().forEach((tween) => {
            const target = tween.targets()[0];
            let newH = gsap.getProperty(target, 'height');
            innerHeight = innerHeight < newH ? newH : innerHeight;
        });

        galleryHeight = galleryHeight > innerHeight ? innerHeight + 'px' : 'auto'

        gsap.set(gallery, {
            maxHeight: galleryHeight
        })
    }

    const updateGallery = () => {
        updateGalleryHeight();

        tl.getChildren().forEach((tween) => {
            const target = tween.targets()[0];
            tween.data.y = scrollHeightPercent(target) * -1;

            updateTrack(target, tween);
        });
    }

    // ScrollTrigger.matchMedia({
    //     // mobile
    //     "(max-width: 767px)": () => {
    //         initTimeline(gallery);
    //         initGallary();
    //     },
    //
    //     // desktop
    //     "(min-width: 768px)": () => {
    //         initTimeline(section);
    //         initGallary();
    //     }
    // });
    
    initTimeline(section);
    initGallery();

    window.addEventListener('resizeEnd', (event) => {
        updateGallery();
        setTimeout(() => {updateGallery()}, 100);
    });
}
