import {gsap} from "gsap";
import { vars } from "../vars";

export function hammerAndSickleAnim() {
  const section = document.querySelector('.hammer-and-sickle-section');

  if(!section) { return false; }

  const title = section.querySelector('.section__title');
  const p = section.querySelectorAll('p');
  const imgs = section.querySelectorAll('.hammer-and-sickle-section__img img');

  gsap.from(title, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    stagger: vars.stagger,
    scrollTrigger: {
      trigger: title,
      start: vars.scrollTrigger.start,
    }
  });

  gsap.from(p, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    stagger: vars.stagger,
    scrollTrigger: {
      trigger: p,
      start: vars.scrollTrigger.start,
    }
  });

  imgs.forEach(img => {
    gsap.from(img, {
      autoAlpha: 0,
      y: '10%',
      scale: 0.5,
      duration: vars.duration,
      scrollTrigger: {
        trigger: img,
        start: vars.scrollTrigger.start,
      }
    });
  });
}