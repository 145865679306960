import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { _isFixed } from "gsap/utils/matrix";
import { vars } from "../vars";

export const planeAnim = () => {
	const section = document.querySelector('.plane');
	
	if (!section) return false

	const titles = section.querySelectorAll('.plane__title span');
	
	titles.forEach((title, i) => {
		const xPosition = (i == 0) ? -100 : 100;
		
		gsap.from(title, {
			scrollTrigger: {
				trigger: title.parentElement,
				start: 'top 80%',
			},
			xPercent: xPosition,
			autoAlpha: 0
		})
	})
	
	// Images animation
	const plane = section.querySelector('.plane__image--plane');
	const camera = section.querySelector('.plane__image--camera');

	const planeClone = plane.cloneNode(true);
	document.body.append(planeClone);

	const cameraAnimation = gsap.from(camera, {
		duration: vars.duration,
		scale: 0.75,
		xPercent: -100,
		yPercent: 100,
		paused: true,
		autoAlpha: 1
	});

	const cameraAnimationOut = gsap.to(camera, {
		duration: vars.duration,
		autoAlpha: 0,
		paused: true,
		onComplete: () => {
			cameraAnimation.restart();
		}
	});

	const planeAnimationSet = () => {
		gsap.set(planeClone, {
			autoAlpha: 0,
			scale: 1,
			xPercent: 0,
			yPercent: 0,
			position: 'absolute'
		});
		gsap.set(plane, {
			autoAlpha: 1
		});
	}
	planeAnimationSet();

	const planeAnimation = gsap.from(plane, {
		duration: vars.duration,
		scale: 0.75,
		xPercent: 100,
		yPercent: -100,
		paused: true,
		autoAlpha: 1
	});

	const planeAnimationOutSet = () => {
		const y = plane.getBoundingClientRect().y;

		gsap.set(plane, {
			autoAlpha: 0
		});
		
		gsap.set(planeClone, {	
			autoAlpha: 1,
			position: 'fixed',
			y: y,
			yPercent: 0,
			xPercent: 0,
			scale: 1,
			zIndex: 1000,
		});
	}

	const planeAnimationOut = gsap.to(planeClone, {
		duration: vars.duration,
		scale: 3.5,
		position: 'fixed',
		autoAlpha: 0,
		y: 0,
		yPercent: 100,
		xPercent: -100 * 1.3,
		paused: true,
		onComplete: () => {
			planeAnimationSet();
			planeAnimation.restart();
		}
	});

	ScrollTrigger.create({
		trigger: '.plane__title',
		start: 'top 70%',
		end: 'bottom 30%',
		// end: 'center',
		once: true,

		onEnter: () => {
			cameraAnimation.play();
			planeAnimationSet();
			planeAnimation.play();
		},
		onLeave: () => {
			cameraAnimationOut.play();

			planeAnimationOutSet();
			planeAnimationOut.play();
		},
	})
}