import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {vars, expandedVars} from '../vars';

export function techDevAnim() {
  const section = document.querySelector('.tech-dev');

  if (!section) return

  const images = document.querySelectorAll('.tech-dev-images img');
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: vars.scrollTrigger.start,
    }
  })

  tl.from(section.querySelector('.tech-dev__content'), {
    y: 100,
    autoAlpha: 0,
    duration: expandedVars.duration.verySlow,
  })

  ScrollTrigger.matchMedia({
    '(min-width: 576px)' : function() {
      images.forEach((image, i) => {
        tl.to(image, {
          duration: 3,
          motionPath: {
            path: '#path',
            align: '#path',
            alignOrigin: [0.5, 0.5],
            end: function () {
              return i / (images.length - 1)
            }
          }
        }, '<')
      })
    }
  });
}