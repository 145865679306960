import {gsap} from 'gsap';
import {expandedVars} from '../vars';

export function bigWorldAnim() {
  const section = document.querySelector('.big-world');
  if (!section) return;

  const images = gsap.utils.toArray('.big-world__decor');
  const largeImage = section.querySelector('.big-world__image img')
  const container = section.querySelector('.container');
  const title = section.querySelector('.big-world__title');
  const content = container.querySelectorAll('p');

  gsap.from(title, {
    y: '1.5em',
    autoAlpha: 0,
    duration: expandedVars.duration.normal,
    scrollTrigger: {
      trigger: title,
      start: expandedVars.scrollTrigger.start,
    }
  })

  content.forEach(p => {
    gsap.from(p, {
      y: '3em',
      autoAlpha: 0,
      duration: expandedVars.duration.normal,
      scrollTrigger: {
        trigger: p,
        start: expandedVars.scrollTrigger.start,
      }
    })
  });

  gsap.from(images, {
    scale: expandedVars.scaleDown.large,
    autoAlpha: 0,
    duration: expandedVars.duration.normal,
    scrollTrigger: {
      trigger: images,
      start: expandedVars.scrollTrigger.start,
    }
  })

  gsap.from(largeImage, {
    scale: expandedVars.scaleDown.large,
    scrollTrigger: {
      trigger: largeImage,
      start: expandedVars.scrollTrigger.start,
    }
  })
}