import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import { expandedVars, vars } from "./vars";

gsap.registerPlugin(ScrollTrigger, SplitText);

export function footerAnim() {

  const section = document.querySelector('.footer');

  if(!section) { return false; }

  let topTitle = section.querySelector('.footer__top .footer__title'),
      topContent = section.querySelectorAll('.footer__top p, .footer__top .lead'),

      bottomTitle = section.querySelector('.footer__bottom .footer__title'),
      bottomContent = section.querySelectorAll('.footer__bottom p'),
      designed = section.querySelectorAll('.footer__bottom .designed-by'),

      gallary = section.querySelector('.gallery__row'),
      astronaut = section.querySelectorAll('.astronaut'),
      planets = section.querySelectorAll('.planet');

    let social = section.querySelector('.social');
    let social_item = social.querySelectorAll('.social__item');
    let socialsCount = social.childElementCount;

    gsap.from(topTitle, {
        autoAlpha: 0,
        x: '-50%',
        duration: vars.duration,
        scrollTrigger: {
            trigger: topTitle,
            start: vars.scrollTrigger.start,
        }
    });

    gsap.from(topContent, {
        autoAlpha: 0,
        x: '50%',
        stagger: vars.stagger,
        duration: vars.duration,
        scrollTrigger: {
            trigger: topContent,
            start: vars.scrollTrigger.start,
        }
    });

    if (gallary) {
        let items = gallary.children;
        items = gsap.utils.toArray(items);
        items.forEach((item, index) => {
            let dir = index % 2 ? '' : '-';
            gsap.from(item, {
                autoAlpha: 0,
                x: dir + '25vw',
                duration: vars.duration,
                scrollTrigger: {
                    trigger: gallary,
                    start: vars.scrollTrigger.start,
                },
            });
        })
    }

    gsap.from(astronaut, {
        autoAlpha: 0,
        y: '-25%',
        duration: expandedVars.duration.slow,
        scrollTrigger: {
            trigger: social,
            start: vars.scrollTrigger.start,
        },
        onComplete: function() {
            gsap.to(astronaut, {
                y: '-25%',
                yoyo: true,
                ease: "power1.inOut",
                duration: expandedVars.duration.verySlow,
                repeat: -1,
            });
        }
    });

    social_item.forEach((item, index) => {
        let dir = index >= Math.floor(socialsCount / 2) ? -1 : 1;
        let x = parseFloat(window.getComputedStyle(item).marginTop);
        x = dir * x + 'px';
        gsap.from(item, {
            autoAlpha: 0,
            y: '100%',
            x: x,
            delay: index * 0.150,
            duration: vars.duration,
            scrollTrigger: {
                trigger: social,
                start: vars.scrollTrigger.start,
            },
        });
    });
    // Simple animation
    // gsap.from('.social__item', {
    //     autoAlpha: 0,
    //     y: '100%',
    //     duration: 1.5,
    //     stagger: 0.15,
    //     scrollTrigger: {
    //         trigger: '.social__item',
    //         start: vars.scrollTrigger.start,
    //     },
    // });

    let bottomTitleLines = new SplitText(bottomTitle, {type: 'lines'});
    bottomTitleLines.lines.forEach(function(line, index) {
        let x = index % 2 ? '25%' : '-25%';
        gsap.from(line, {
            autoAlpha: 0,
            x: x,
            duration: expandedVars.duration.slow,
            scrollTrigger: {
                trigger: bottomTitle,
                start: vars.scrollTrigger.start,
            },
        });
    });

    gsap.from(bottomTitle, {
        autoAlpha: 0,
        y: '25%',
        duration: expandedVars.duration.slow,
        scrollTrigger: {
            trigger: bottomTitle,
            start: vars.scrollTrigger.start,
        },
    });
    
    gsap.from(bottomContent, {
        autoAlpha: 0,
        y: '3em',
        duration: expandedVars.duration.slow,
        scrollTrigger: {
            trigger: bottomContent,
            start: vars.scrollTrigger.start,
        },
    });

    gsap.from(designed, {
        autoAlpha: 0,
        y: '100%',
        duration: expandedVars.duration.slow,
        scrollTrigger: {
            trigger: designed,
            start: vars.scrollTrigger.start,
        },
    });

    planets.forEach((planet, index) => {
        gsap.from(planet, {
            autoAlpha: 0,
            y: '50%',
            duration: vars.duration,
            scrollTrigger: {
                trigger: planet,
                start: vars.scrollTrigger.start,
            },
            onComplete: function() {
                if (planet.classList.contains('planet--mars')) {
                    gsap.to(planet, {
                        y: '-15%',
                        yoyo: true,
                        ease: "power1.inOut",
                        duration: expandedVars.duration.verySlow,
                        repeat: -1,
                    });
                }
            }
        });
    });
};
