import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// end-great-future-section
export function endGreatFutureAnim() {
  const section = document.querySelector('.end-great-future-section');

  if(!section) { return false; }

  let title = section.querySelectorAll('.section__title'),
      content = section.querySelectorAll('p'),
      gallery = section.querySelectorAll('.gallery'),
      airplane = section.querySelectorAll('.airplane');

    let targets = gsap.utils.toArray([title, content, gallery]);


    targets.forEach((item, index) => {
        let y = item.classList.contains('section__title') ? '1.5em' : '3em';
        gsap.from(item, {
            autoAlpha: 0,
            y: y,
            duration: 1,
            scrollTrigger: {
                trigger: item,
                start: "top 80%",
            }
        });
    });

    gsap.from(airplane, {
        autoAlpha: 0,
        x: '100%',
        y: '-100%',
        duration: 1,
        scrollTrigger: {
            trigger: airplane,
            start: "top 80%",
        }
    });

    let slider = gsap.to(gallery, {
        onUpdate: function() {
            gsap.set(gallery, {
                xPercent: -10 * this.progress()
            });
        },
        scrollTrigger: {
            trigger: gallery,
            scrub: 1
        }
    });
};
