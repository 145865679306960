import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import { clearStyles } from "../utils/clearStyles";
import { vars } from "../vars";

export function heroAnim() {
  const hero = document.querySelector('.hero');
  
  if (!hero) return false;

  let self = this !== window ? this : false;
  if (self && self.scroller) {
    self.scroller.paused(true);
  }

  const tl = gsap.timeline(),
    title = hero.querySelector(".hero-title"),
    mySplitText = new SplitText(title, {type: "words,chars"}),
    chars = mySplitText.chars, //an array of all the divs that wrap each character
    rocket = title.querySelector('.hero-title__rocket')

  tl.from(chars, {
    delay: 1,
    duration: vars.duration,
    yPercent: 100,
    stagger: 0.02
  });

  tl.from(rocket, {
    xPercent: -200,
    yPercent: 100,
    duration: vars.duration,
    ease: "power2.out",
    onComplete: function() {
      rocketAnimOut();

      if (self && self.scroller) {
        self.scroller.paused(false);
      }
    }
  }, '-=1.5');
  

  const rocketAnimOut = () => {
    const transform = gsap.getProperty(rocket, 'transform');

    gsap.fromTo(rocket,
      {
        transform: transform
      },
      {
        duration: vars.duration,
        xPercent: 200,
        yPercent: -200,
        ease: "power2.out",
  
        scrollTrigger: {
          start: 'top 5%',
          trigger: title
        },
  
        onComplete: () => {
          rocketBack(transform)
        }
      })
  };
  
  const rocketBack = (transform) => {
    gsap.fromTo(rocket, {
      transform: transform,
      autoAlpha: 0,
    },{
      autoAlpha: 1,
      delay: vars.duration,
      duration: vars.duration,
      onComplete: () => {
        clearStyles(rocket)
      }
    })
  }
}