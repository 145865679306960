import {gsap} from 'gsap';
import {SplitText} from 'gsap/SplitText';
import {vars} from '../vars';

export function todayDreamsAnim() {
  const section = document.querySelector('.today-dreams')
  if (!section) return;

  const titleRed = section.querySelector('.today-dreams__title--red');
  const splitTitle = new SplitText(titleRed, {type: 'chars'});
  const titles = section.querySelectorAll('.today-dreams__title strong');
  const content = section.querySelectorAll('.today-dreams__content p');
  
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: titleRed,
      start: vars.scrollTrigger.start,
    }
  });
  
  tl.from(splitTitle.chars, {
    y: 200,
    autoAlpha: 0,
    stagger: 0.05
  })

  // titles.forEach((title, index) => {
  //   tl.from(title, {
  //     x: -300,
  //     autoAlpha: 0,
  //     delay: 0.3
  //   }, '-=0.3')
  // })
  
  // First part of title anim
  tl.from(titles[0], {
    x: -300,
    autoAlpha: 0,
    delay: 0.3
  }, '-=0.3')
  
  // First part of content anim
  tl.from(content[0], {
    x: 300,
    autoAlpha: 0
  }, '<')
  
  // Second part of title anim
  tl.from(titles[1], {
    x: -300,
    autoAlpha: 0,
    delay: 0.3
  }, '-=0.3')
  
  // Second part of content anim
  tl.from(content[1], {
    x: 300,
    autoAlpha: 0
  }, '<')

  // content.forEach(item => {
  //   gsap.from(item, {
  //     x: 300,
  //     autoAlpha: 0,
  //     scrollTrigger: {
  //       trigger: item,
  //       start: vars.scrollTrigger.start,
  //     }
  //   })
  // })

  const imagesContainer = document.querySelector('.today-dreams-images')
  const images = gsap.utils.toArray('.today-dreams-images__item');

  window.addEventListener('load', () => {
    let width = images.reduce((total, current) => total + current.offsetWidth, 0)
    gsap.to(imagesContainer, {
      x: () => width - document.documentElement.clientWidth,
      scrollTrigger: {
        trigger: imagesContainer,
        start: 'top center',
      }
    })
  })
}