const expandedVars = { 
    duration: {
        fast: 0.75,
        normal : 1,
        slow: 1.5,
        verySlow: 2,
    },
    stagger: {
        fast: 0.1,
        normal: 0.15,
        slow: 0.2
    },
    scrollTrigger: {
        start: "top 80%",
    },
    delay: {
        fast: 0.1,
        normal: 0.15,
        slow: 0.2,
        verySlow: 0.4
    },
    scaleDown: {
        large: 0.5,
        middle: 0.8,
        small: 0.9
    },
    scaleUp: {
        extraLarge: 2,
        large: 1.5,
        middle: 1.2,
        small: 1.1
    }
};

const vars = Object.keys(expandedVars)
    .filter( key => (expandedVars[key]) )
    .reduce( (res, key) => (res[key] = expandedVars[key].normal || expandedVars[key], res), {} );

export {vars, expandedVars}