import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {isVisible} from "../utils/isVisible";
import { vars } from "../vars";

export function flowersAnim() {
  const section = document.querySelector('.flowers-section');

  if (!section) { return false; }

  let title = section.querySelectorAll('.flowers-section__title'),
    p = section.querySelectorAll('.flowers-section__content p'),
    img = section.querySelectorAll('.flowers-section img');

  if(title.length < 1) { return false; }

  title = Array.prototype.slice.call(title);
  title = title.filter(isVisible)[0];

  let titleOffsetHeight = title.offsetHeight;
  let linesSplitText = new SplitText(title, {type: 'lines'});

  if (titleOffsetHeight !== title.offsetHeight) {
    linesSplitText.revert().split();
  }

  let lines = linesSplitText.lines;
  lines = lines.filter(isVisible);

  lines.forEach((line, index) => {
    gsap.from(line, {
      autoAlpha: 0,
      x: (index % 2 ? '' : '-') + '14%',
      duration: vars.duration,
      scrollTrigger: {
        trigger: line,//'.flowers-section',
        start: vars.scrollTrigger.start,
      }
    });
  });

  gsap.from(p, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    stagger: vars.stagger,
    scrollTrigger: {
      trigger: p,
      start: vars.scrollTrigger.start,
    }
  });

  function grayscaleAnimation(tween) {
    gsap.fromTo( tween.targets(), {
      filter: "grayscale(100%)",
    },
    {
      delay: vars.delay.verySlow,
      duration: vars.duration,
      filter: "grayscale(0%)",
    });
  }

  gsap.from(img[0], {
    autoAlpha: 0,
    x: '-100%',
    duration: vars.duration,
    scrollTrigger: {
      trigger: p,//'.flowers-section',
      start: vars.scrollTrigger.start,
    },
    onComplete: function() {
      grayscaleAnimation(this);
    }
  });

  gsap.from(img[1], {
    autoAlpha: 0,
    y: '100%',
    duration: vars.duration,
    scrollTrigger: {
      trigger: p,//'.flowers-section',
      start: vars.scrollTrigger.start,
    },
    onComplete: function() {
      grayscaleAnimation(this);
    }
  });

  gsap.from(img[2], {
    autoAlpha: 0,
    y: '100%',
    duration: vars.duration,
    scrollTrigger: {
      trigger: p,//'.flowers-section',
      start: vars.scrollTrigger.start,
    },
    onComplete: function() {
      grayscaleAnimation(this);
    }
  });

  gsap.from(img[3], {
    autoAlpha: 0,
    x: '100%',
    duration: vars.duration,
    scrollTrigger: {
      trigger: p,//'.flowers-section',
      start: vars.scrollTrigger.start,
    },
    onComplete: function() {
      grayscaleAnimation(this);
    }
  });
}