import {gsap} from "gsap";
import { vars } from "../vars";

export function frArticleAnim() {
  const section = document.querySelector('.fr-article');

  if (!section) return false;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: vars.scrollTrigger.start,
    }
  });

  const title = section.querySelectorAll('.fr-article__title');
  const p = section.querySelectorAll('.js-paragraph-anim p');
  const image = section.querySelectorAll('.js-scale-anim');

  tl.from(title, {
    y: '3em',
    autoAlpha: 0,
    duration: vars.duration
  });

  tl.from(p, {
    y: '3em',
    autoAlpha: 0,
    duration: vars.duration
  });

  tl.from(image, {
    scale: 1.5,
    autoAlpha: 0,
    duration: vars.duration
  },"<");
}