import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

const sections = gsap.utils.toArray('.horizontal-scroll__item');
const vh = (coef) => window.innerHeight * (coef/100);

function thirdSlide(containerAnimation = false, isDesktop = false) {
  let thirdSlide = gsap.timeline({
    scrollTrigger: {
      trigger: sections[2],
      containerAnimation: containerAnimation,
      toggleActions: 'play none none reverse',
    }
  })

  thirdSlide.from(sections[2].querySelector('.content-wrapper'), {
    y: 300,
    autoAlpha: 0,
  });
  if (isDesktop) {
    thirdSlide.to(sections[1].querySelector('.container'), {
      x: -90,
      autoAlpha: 0,
    }, '<')
  }
  thirdSlide.addLabel('showImages');
  let imagesTop = sections[2].querySelectorAll('.item-image--top'),
    imagesBottom = sections[2].querySelectorAll('.item-image--bottom');

  imagesTop.forEach((item) => {
    thirdSlide.from(item, {
      autoAlpha: 0,
      y: -500,
    }, 'showImages')
  });

  imagesBottom.forEach((item) => {
    thirdSlide.from(item, {
      autoAlpha: 0,
      y: 500,
    }, 'showImages')
  })

  thirdSlide.from(sections[2].querySelector('.item-image--center.item-image--left'), {
    autoAlpha: 0,
    x: -300,
    // yPercent: -50,
    rotate: 37,
  }, 'showImages')
  thirdSlide.from(sections[2].querySelector('.item-image--center.item-image--right'), {
    autoAlpha: 0,
    x: 300,
    // yPercent: -50,
    rotate: -40,
  }, 'showImages')
}

function fourthSlide(containerAnimation = false, isDesktop = false) {
  let fourthSlide = gsap.timeline({
    scrollTrigger: {
      trigger: sections[3],
      containerAnimation: containerAnimation,
      toggleActions: 'play none none reverse',
    }
  })

  fourthSlide.from(sections[3].querySelector('.container'), {
    y: 200,
    autoAlpha: 0,
  })

  let imagesWrapper = sections[3].querySelector('.horizontal-images');
  let images = imagesWrapper.querySelectorAll('.horizontal-images__item');

  fourthSlide.from(imagesWrapper, {
    autoAlpha: 0,
    y: 50,
  })

  fourthSlide.to(images, {
    x: () => -(imagesWrapper.scrollWidth - document.documentElement.clientWidth) + 'px',
    ease: 'power3.out',
    duration: 2,
  })
}

let mainTl;

export function horizontalScroll() {
  if (!sections.length) return;

  ScrollTrigger.matchMedia({
    // desktop
    '(min-width: 768px)': () => {
      if(mainTl) {
        mainTl.kill()
      }
      mainTl = gsap.timeline({
        scrollTrigger: {
          trigger: ".horizontal-scroll",
          pin: true,
          scrub: 1,
          onToggle: () => {
            document.querySelector('.horizontal-scroll__item:first-child').classList.toggle('is-active')
          },
          end: `+=${sections.length * vh(100)}px`
        }
      })
      
      mainTl.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
      });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: sections[0],
          toggleActions: 'play none none reverse',
          start: 'top 40%',
        }
      })
      .from(sections[0].querySelector('.sbs-block__image img'), { scale: 2, duration: 0.5 })
      .from(sections[0].querySelector('.sbs-block__content'), { y: 50, duration: 0.5, autoAlpha: 0 }, '<')

      gsap.timeline({
        scrollTrigger: {
          trigger: sections[1],
          containerAnimation: mainTl,
          start: 'left 70%',
          toggleActions: 'play none none reverse'
        }
      })
      .from('.scale-on-enter', { scale: 2, duration: 0.5 })
      .from(sections[1].querySelector('.sbs-block__content'), { autoAlpha: 0 }, '<')
      .to(sections[0].querySelector('.container'), { autoAlpha: 0, x: -90 }, '<')

      thirdSlide(mainTl, true)
      fourthSlide(mainTl, true);
    },
    // mobile
    '(max-width: 767px)': () => {
      sections.forEach((section, i) => {
        section.style.zIndex = -i;
        gsap.set(section, { xPercent: 0 })
      })
      const firstSlideImg = sections[0].querySelector('.sbs-block__image img');
      const firstSlideContent = sections[0].querySelector('.sbs-block__content');

      gsap.from(firstSlideImg, {
        scale: 2,
        duration: 0.5,
        scrollTrigger: {
          trigger: firstSlideImg,
          start: 'top 80%'
        }
      })

      gsap.from(firstSlideContent, {
        y: 100,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: sections[0],
          start: 'top center'
        }
      })

      gsap.from('.scale-on-enter', {
        scale: 2,
        duration: 0.5,
        scrollTrigger: {
          trigger: sections[1].querySelector('.sbs-block__images'),
          toggleActions: 'play none none reverse',
          start: 'top center'
        }
      })
      gsap.from(sections[1].querySelector('.sbs-block__content'), {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: sections[1],
          toggleActions: 'play none none reverse',
          start: 'top center'
        }
      })

      thirdSlide()
      fourthSlide()
    }
  })
}