import {gsap} from "gsap";
import { vars } from "../vars";

export function ovalThumbAnim() {
  const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.oval-thumb-section',
        start: 'top 20%',
      }
    }),
    title = document.querySelectorAll('.oval-thumb-section__title'),
    p = document.querySelectorAll('.oval-thumb-section__content p'),
    img = document.querySelector('.oval-thumb-section .oval-thumb img');

  tl.from(title, {
    autoAlpha: 0,
    y: '1.5em',
    duration: vars.duration
  }, 0);

  tl.from(img, {
    autoAlpha: 0,
    y: '15%',
    duration: vars.duration
  }, 0);
  tl.from(p, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    stagger: vars.stagger
  }, 0);
}