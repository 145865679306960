const scrollHeightPercent = (el) => {
    if (!el) return false;
    let y = (el.scrollHeight - el.clientHeight) / el.clientHeight * 100;
    return y;
}


const scrollWidthPercent = (el) => {
    if (!el) return false;
    let x = (el.scrollWidth - el.clientWidth) / el.clientWidth * 100;
    return x;
}

export {
    scrollHeightPercent,
    scrollWidthPercent
}