import gsap from 'gsap';
import { vars } from '../vars';

export const headerAnim = () => {
	const header = ".header";
	gsap.from(header, {
		duration: 1,
		yPercent: -200,
		opacity: 0,
		duration: vars.duration
	});
}