import {gsap} from 'gsap';
import {SplitText} from 'gsap/SplitText';
import {nestedLinesSplit} from '../utils/nestedLines';
import {vars} from '../vars';

const getContentWidth = (element) => {
  const styles = getComputedStyle(element)

  return element.clientWidth
    - parseFloat(styles.paddingLeft)
    - parseFloat(styles.paddingRight)
}

const getIndent = (elementFirst, elementSecond) => {
  let indent = 0;
  const styles = getComputedStyle(elementSecond);
  const elementFirstWidth = getContentWidth(elementFirst);
  const elementSecondWidth = getContentWidth(elementSecond);
  
  indent = ((elementFirstWidth - elementSecondWidth) / 2) - parseFloat(styles.paddingLeft);

  return indent;
}

const fadeIn = function(el, scrollTrigger = true) {
  return gsap.from(el, {
    y: '2em',
    autoAlpha: 0,
    scrollTrigger: scrollTrigger ? {
      trigger: el,
      start: vars.scrollTrigger.start,
      once: true
    }: false,
  })
}

const splitLines = (split, indent = 0) => {
  split.lines.forEach((line) => {
    gsap.set(line, {textAlign: 'center'});
    const lineInner = new SplitText(line, {type: 'lines'});
    gsap.set(lineInner.lines, {display: 'inline-block'})
    const x = line.children[0].getBoundingClientRect().x - line.getBoundingClientRect().x + indent;
    gsap.set(line, {
      textAlign: 'left',
      x: x
    });
  });
}

export function darkLightSectionAnimation() {
  const section = document.querySelector('.dark-light-sections');

  if (!section) return;

  const lightSection = section.querySelector('.light-section');

  const title = section.querySelector('.dark-section__title');
  const splitTitle = nestedLinesSplit(title, {type: 'lines'})

  const desc = section.querySelector('.dark-section__desc');
  const splitDesc = new SplitText(desc, {type: 'lines'})
  
  const darkSectionBg = section.querySelector('.dark-section__background');

  const image = section.querySelector('.dark-section__image img');
  const titleLight = lightSection.querySelector('.light-section__title');

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: lightSection,
      start: vars.scrollTrigger.start,
      once: true
    }
  })

  const indentTitle =  getIndent (darkSectionBg, title);
  const indentDesc =  getIndent (darkSectionBg, desc);

  splitLines(splitTitle, indentTitle);
  splitLines(splitDesc, indentDesc);

  fadeIn(title);
  fadeIn(desc);
  
  tl.to([splitTitle.lines, splitDesc.lines], {
    x: 0,
    duration: vars.duration,
    onComplete: () => {
      window.addEventListener('resizeOnce', () => {
        splitTitle.revert();
      })
    }
  })

  tl.from(image, {
    xPercent: 120,
    duration: vars.duration
  }, '<')

  tl.from(titleLight, {
    y: '2em',
    autoAlpha: 0,
    duration: vars.duration
  }, '<')
}