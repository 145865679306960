import {gsap} from "gsap";
import { vars } from "../vars";

export function redRevolutionAnim() {
  let title = document.querySelectorAll('.red-revolution-section__title'),
    content = document.querySelectorAll('.red-revolution-section__content'),
    imgs = document.querySelectorAll('.red-revolution-section .col-img img');


  gsap.from(title, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    stagger: vars.stagger,
    scrollTrigger: {
      trigger: title,
      start: vars.scrollTrigger.start,
    }
  });

  gsap.from(content, {
    autoAlpha: 0,
    y: '3em',
    duration: vars.duration,
    stagger: vars.stagger,
    scrollTrigger: {
      trigger: content,
      start: vars.scrollTrigger.start
    }
  });

  imgs.forEach(function(img) {
    gsap.from(img, {
      autoAlpha: 0,
      y: '3em',
      duration: vars.duration,
      scrollTrigger: {
        trigger: img,
        start: vars.scrollTrigger.start
      }
    });
  })
}