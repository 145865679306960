import {gsap} from "gsap";
import { clearTweenStyles } from "../utils/clearStyles";

function dreamTechnologyAnimDefault() {
	return {
		ease: "power2.out",
		autoAlpha: 0,
		duration: 1,
		yPercent: 10,
		onComplete: function() {
			clearTweenStyles(this);
		}
	}
}

export function dreamTechnologyAnim() {
	const section = document.querySelector('.dream-technology');
	
	if (!section) {
		return false;
	}
	
	const tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				// toggleActions: 'restart reverse restart reverse',
				start: '20% bottom',
			},
		}),
		title = section.querySelector('.dream-technology__title'),
		content = section.querySelector('.dream-technology__content');
	
	tl.from(title, dreamTechnologyAnimDefault())
	tl.from(content, dreamTechnologyAnimDefault(), '<')

	const images = document.querySelectorAll('.dream-technology__image');

	images.forEach((image) => {
		let params = Object.assign({
			scrollTrigger: {
				trigger: image,
			},
		}, dreamTechnologyAnimDefault());
		gsap.from(image, params)
	})
}

export default dreamTechnologyAnim;