import {gsap} from "gsap";
import { vars, expandedVars } from "../vars";

export function uncleSamAnim() {
  const section = document.querySelector('.uncle-sam');

  if (!section) {
    return false;
  }

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
    },
    defaults: {
      duration: vars.duration,
      yPercent: 40,
      autoAlpha: 0,
      ease: "power2.out"
    }
  });
  const title = document.querySelector('.uncle-sam .futurism-title'),
    portrait = document.querySelector('.uncle-sam-col__portrait'),
    paragraphs = document.querySelectorAll('.uncle-sam-col__inner p'),
    stars = document.querySelector('.uncle-sam__stars');

  tl.from(title, {})
    .from(portrait, {}, ('-=' + vars.duration * 0.7))

  gsap.from(stars, {
    autoAlpha: 0,
    duration: expandedVars.duration.verySlow,
    scrollTrigger: {
      trigger: section,
      toggleActions: 'play none none reset'
    }
  });

  paragraphs.forEach(paragraph => {
    tl.from(paragraph, {}, ('-=' + vars.duration * 0.9))
  })
}