import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export function ovalImageAnim() {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.oval-image__text',
      start: 'top 80%',
    }
  }),
    titleParagraphs = document.querySelectorAll('.oval-image__title p'),
    titleTl = gsap.timeline(),
    images = document.querySelectorAll('.oval-image__img'),
    imagesTl = gsap.timeline();

  titleParagraphs.forEach((paragraph, i) => {
    const xDirection = (i === 0) ? -10 : 10;

    gsap.set(paragraph, {
      xPercent: xDirection,
      autoAlpha: 0,
    })

    titleTl.to(paragraph, {
      xPercent: 0,
      autoAlpha: 1,
      duration: 1,
      ease: "power2.out"
    })
  })

  tl.add(titleTl);

  gsap.set('.oval-image__subtitle', {
    yPercent: 20,
    autoAlpha: 0,
  })

  tl.to('.oval-image__subtitle', {
    yPercent: 0,
    autoAlpha: 1,
    duration: 1
  }, '-=1.5')

  images.forEach(image => {
    gsap.set(image, {
      autoAlpha: 0,
      scale: 0,
    })
    imagesTl.to(image, {
      autoAlpha: 1,
      scale: 1,
      duration: 0.35
    })
  })

  tl.add(imagesTl, '-=1')
}