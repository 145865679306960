import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import { vars } from "../vars";

export function gigantomaniaAnim() {
  const section = document.querySelector('.gigantomania-section');

  if(!section) { return false; }

  let title = section.querySelectorAll('.section__title'),
    linesSplitText = new SplitText(title, {
      type: 'lines'
    }),
    subtitle = section.querySelectorAll('.section__subtitle'),
    content = section.querySelectorAll('p'),
    posters = section.querySelectorAll('.poster'),
    zeppelins = section.querySelectorAll('.zeppelin'),
    planet = section.querySelectorAll('.planet');

  let lines = linesSplitText.lines;

  lines.forEach((line, index) => {
    gsap.from(line, {
      autoAlpha: 0,
      x: (index % 2 ? '-' : '') + '14%',
      duration: vars.duration,
      scrollTrigger: {
        trigger: line,
        start: vars.scrollTrigger.start,
      }
    });
  });

  gsap.from(zeppelins, {
    autoAlpha: 0,
    x: '25%',
    duration: vars.duration,
    scrollTrigger: {
      trigger: zeppelins,
      start: vars.scrollTrigger.start,
    }
  });

  gsap.from(subtitle, {
    autoAlpha: 0,
    y: '1.5em',
    duration: vars.duration,
    scrollTrigger: {
      trigger: subtitle,
      start: vars.scrollTrigger.start,
    }
  });

  content.forEach(item => {
    gsap.from(item, {
      autoAlpha: 0,
      y: '3em',
      duration: vars.duration,
      scrollTrigger: {
        trigger: item,
        start: vars.scrollTrigger.start,
      }
    });
  });

  function cleanStyles(tween) {
    let targets = tween.targets();
    targets.forEach(function(item) {
      item.setAttribute('style', '');
    });
  }

  posters.forEach(function(poster, index) {
    if (index === 0 || index === 1) {
      gsap.from(poster, {
        autoAlpha: 0,
        scale: vars.scaleDown.middle,
        // x: '-100%',
        duration: vars.duration,
        scrollTrigger: {
          trigger: poster,
          start: vars.scrollTrigger.start,
        },
        onComplete: function() {
          cleanStyles(this)
        }
      });
    }
    else {
      gsap.from(poster, {
        autoAlpha: 0,
        scale: vars.scaleDown.middle,
        x: '-100%',
        y: '-50%',
        duration: vars.duration,
        scrollTrigger: {
          trigger: poster,
          start: vars.scrollTrigger.start,
        },
        onComplete: function() {
          cleanStyles(this)
        }
      });
    }
  });

  gsap.from(planet, {
    autoAlpha: 0,
    x: '-10%',
    scale: vars.scaleDown.middle,
    duration: vars.duration,
    scrollTrigger: {
      trigger: planet,
      start: vars.scrollTrigger.start,
    }
  });
}