import {gsap} from 'gsap';
import {expandedVars} from '../vars';

export function distantStarsAnim() {
  const section = document.querySelector('.distant-stars');

  if (!section) return;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: 'top center',
    }
  })
  tl.from(section, {
    autoAlpha: 0,
  })
  tl.from('.distant-stars__img img', {
    scale: expandedVars.scaleUp.large
  })
  tl.fromTo('.distant-stars__content p', {
    autoAlpha: 0.5,
    y: 200,
  }, {
    autoAlpha: 1,
    y: 0,
  }, '<')
}